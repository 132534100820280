import React, { useCallback, useState } from 'react';

import { Image as ImageType } from '@AuroraTypes';
import { CarouselProps } from '@Components/Carousel/Carousel';
import { LoadableCarousel } from '@Components/Carousel/LoadableCarousel';
import { GalleryModalProps } from '@Components/Modal/GalleryModal';
import { LoadableGalleryModal } from '@Components/Modal/LoadableGalleryModal';
import { GalleryImage } from '@UX/Image/GalleryImage';

export interface ImageGalleryCarouselProps
  extends Pick<GalleryModalProps, 'onCloseClick'>,
    Pick<CarouselProps, 'name' | 'variant' | 'initialSlideIndex'> {
  images: ImageType[];
}

export const ImageGalleryCarousel: React.FC<ImageGalleryCarouselProps> = ({
  name,
  variant,
  images,
  onCloseClick,
  initialSlideIndex,
}) => {
  const [isPreloadAllowed, setIsPreloadAllowed] = useState(false);

  const onLoad = useCallback(() => {
    setIsPreloadAllowed(true);
  }, [setIsPreloadAllowed]);

  return (
    <LoadableGalleryModal onCloseClick={onCloseClick}>
      <LoadableCarousel
        name={name}
        variant={variant}
        initialSlideIndex={initialSlideIndex}
        dataId="image-gallery-carousel"
      >
        {images.map((image) => (
          <GalleryImage
            key={image.url}
            image={image}
            lazy={!isPreloadAllowed}
            onLoadCallback={onLoad}
            quality={70}
          />
        ))}
      </LoadableCarousel>
    </LoadableGalleryModal>
  );
};
