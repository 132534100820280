import { Image } from '@loveholidays/design-system';
import React from 'react';

import { Image as ImageType } from '@AuroraTypes';

interface GalleryImageProps {
  image: ImageType;
  lazy: boolean;
  quality?: number;
  onLoadCallback?: () => void;
}
export const GalleryImage: React.FC<GalleryImageProps> = ({
  image,
  lazy,
  quality,
  onLoadCallback = () => {},
}) => (
  <Image
    fit="bounds"
    src={image.url}
    alt={image.description}
    width={[768, 1200, 1600]}
    lazy={lazy}
    onLoadCallback={onLoadCallback}
    sx={{
      maxWidth: ['100%', null, '1200px'],
    }}
    quality={quality}
  />
);
