import React, { FC, Fragment } from 'react';

import { Image as ImageType } from '@AuroraTypes';
import { LoadableMasonryGridModal } from '@Components/NewGallery/LoadableMasonryModal';
import { useFeatureFlag } from '@Core/octopus/useFeatureFlag';
import { ImageGalleryCarousel } from '@UX/Image/ImageGalleryCarousel';

type ProductCardGalleryProps = {
  setGalleryClose: () => void;
  images: ImageType[];
  imageIndex: number;
  name: string;
};

export const ProductCardGallery: FC<ProductCardGalleryProps> = ({
  setGalleryClose,
  images,
  imageIndex,
  name,
}) => {
  const newProductCardGallery = useFeatureFlag('NewProductCardGallery');

  return (
    <Fragment>
      {!newProductCardGallery && (
        <ImageGalleryCarousel
          onCloseClick={setGalleryClose}
          name="hotel-summary"
          variant="lightbox"
          initialSlideIndex={imageIndex}
          images={images}
        />
      )}

      {newProductCardGallery && (
        <LoadableMasonryGridModal
          closeModal={setGalleryClose}
          images={images}
          title={name}
          activeIndex={imageIndex}
        />
      )}
    </Fragment>
  );
};
